import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TranslatedText from 'components/i18n/TranslatedText';
import Label from 'components/admin2/ui/Label';
import Dragger from 'components/admin2/LegacyDragger';
import { AddButton } from 'components/admin2/ui/IconButton';

import {
  ADMIN_TEXT_100,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_300,
} from 'style/constants';
import { getKey } from 'utils';
import CollapsibleItem from 'components/admin2/ui/CollapsibleItem';
import { ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';
import { CollapsibleListContainer } from './styles';

export const NoItems = styled.div`
  ${ADMIN_TEXT_LABEL_XS_MEDIUM}
  color: ${ADMIN_TEXT_300};
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background: transparent;
  border: 1px solid ${ADMIN_SURFACE_5};
`;

const NoItemsText = styled.span`
   ${ADMIN_TEXT_100}
`;

export default class CollapsibleList extends React.Component {
  static propTypes = {
    addItemKey: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    draggable: PropTypes.bool,
    emptyListKey: PropTypes.string,
    fallbackImageIcon: PropTypes.string,
    headerText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    iconName: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
    imageSrc: PropTypes.string,
    keyField: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
    labelKey: PropTypes.string,
    list: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    nameKey: PropTypes.string,
    nameText: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
    noPadding: PropTypes.bool,
    onAddItem: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onDragEnd: PropTypes.func,
    renderOnEmptyList: PropTypes.func,
    secondaryColor: PropTypes.bool,
    testIdAddButton: PropTypes.string,
    testIdRemove: PropTypes.string,
    testIdTitle: PropTypes.string,
    testIdUrl: PropTypes.string,
    useInnerDeleteButton: PropTypes.bool,
  };

  static defaultProps = {
    draggable: undefined,
    emptyListKey: undefined,
    fallbackImageIcon: undefined,
    headerText: '',
    iconName: undefined,
    imageSrc: undefined,
    labelKey: undefined,
    nameKey: undefined,
    nameText: undefined,
    noPadding: false,
    onDelete: undefined,
    onDragEnd: undefined,
    renderOnEmptyList: () => null,
    secondaryColor: false,
    testIdAddButton: undefined,
    testIdRemove: undefined,
    testIdTitle: undefined,
    testIdUrl: undefined,
    useInnerDeleteButton: false,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    itemsCollapsed: this.props.list.map(() => true),
  };

  componentDidUpdate(prevProps) {
    const { itemsCollapsed } = this.state;
    const { list: newList } = this.props;
    const { list: oldList } = prevProps;
    if (newList.length > oldList.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        itemsCollapsed: newList.map((_, i) => (
          itemsCollapsed[i] === undefined ? true : itemsCollapsed[i])),
      });
    }
  }

  handleDelete = (e, index) => {
    e.stopPropagation();
    const { onDelete } = this.props;
    const { itemsCollapsed: oldItemsCollapsed } = this.state;
    const itemsCollapsed = [...oldItemsCollapsed];
    itemsCollapsed.splice(index, 1);
    onDelete(index);
    this.setState({
      itemsCollapsed,
    });
  };

  toggleCollapsed = (index) => {
    const { itemsCollapsed } = this.state;
    this.setState({
      itemsCollapsed: itemsCollapsed.map(
        (collapsedState, i) => index === i ? !collapsedState : collapsedState,
      ),
    });
  };

  renderItem = (item, i) => {
    const {
      draggable,
      fallbackImageIcon,
      iconName,
      imageSrc,
      nameText,
      nameKey,
      children,
      onDelete,
      secondaryColor,
      testIdRemove,
      testIdTitle,
      testIdUrl,
      headerText,
      keyField,
      useInnerDeleteButton,
    } = this.props;
    const { itemsCollapsed } = this.state;
    const open = !itemsCollapsed[i];

    return (
      <React.Fragment key={getKey(keyField, item)}>
        <CollapsibleItem
          confirmRemoval
          draggable={draggable}
          fallbackImageIcon={typeof fallbackImageIcon === 'string' ? fallbackImageIcon : fallbackImageIcon?.(item, i)}
          icon={typeof iconName === 'string' ? iconName : iconName?.(item, i)}
          imageSrc={typeof imageSrc === 'string' ? imageSrc : imageSrc?.(item, i)}
          name={typeof nameText === 'string' ? nameText : nameText?.(item, i)}
          nameKey={typeof nameKey === 'string' ? nameKey : nameKey?.(item, i)}
          nameTestId={testIdTitle && `${testIdTitle}-${i}`}
          onEdit={() => this.toggleCollapsed(i)}
          onRemove={useInnerDeleteButton ? undefined : onDelete && (e => this.handleDelete(e, i))}
          onSelect={() => this.toggleCollapsed(i)}
          open={open}
          secondaryRowColor={secondaryColor}
          testIdRemove={testIdRemove && `${testIdRemove}-${i}`}
          type={typeof headerText === 'string' ? headerText : headerText?.(item, i)}
          typeNameTestId={testIdUrl && `${testIdUrl}-${i}`}
        >
          {open && children(item, i, this.handleDelete)}
        </CollapsibleItem>
      </React.Fragment>
    );
  };

  renderList() {
    const {
      draggable,
      list,
      renderOnEmptyList,
      emptyListKey,
      onDragEnd,
    } = this.props;
    // might be able to remove renderOnEmptyList, pending design decision.
    if (list.length === 0 && (renderOnEmptyList || emptyListKey)) {
      return emptyListKey ? (
        <NoItems>
          <TranslatedText component={NoItemsText} stringKey={emptyListKey} />
        </NoItems>
      ) : renderOnEmptyList();
    }
    return draggable ? (
      <Dragger
        direction="vertical"
        droppableStyles={{ width: '100%' }}
        innerDivStyles={{ width: '100%' }}
        keyField="generatedKey"
        list={list}
        onDragEnd={onDragEnd}
        stretch
      >
        {this.renderItem}
      </Dragger>
    ) : list.map(this.renderItem);
  }

  render() {
    const { labelKey, onAddItem, addItemKey, noPadding, testIdAddButton } = this.props;
    return (
      <CollapsibleListContainer noPadding={noPadding}>
        {
          labelKey && (
            <Label
              labelKey={labelKey}
            />
          )
        }
        { this.renderList() }
        {
          addItemKey && onAddItem && (
            <AddButton
              data-testid={testIdAddButton}
              onClick={onAddItem}
              spacing="10px 0 0 0"
            >
              <TranslatedText stringKey={addItemKey} />
            </AddButton>
          )
        }
      </CollapsibleListContainer>
    );
  }
}
