import styled from 'styled-components';
import {
  ADMIN_SURFACE_4,
} from 'style/constants';

export const CollapsibleListContainer = styled.div<{noPadding?: boolean}>`
  ${({ noPadding }) => !noPadding && 'padding: 10px;'}
  background: ${ADMIN_SURFACE_4};
  border-radius: 5px;
`;
