import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { DateRangeProps } from 'components/admin2/DateRangePicker';
import {
  CalendarContainer,
  CalendarHeader,
  Title,
  Tip,
  Circle,
  CheckIcon,
  NextButton,
  PreviousButton,
  CalendarYearInput,
  SelectorContainer,
  SelectorTitle,
} from 'components/admin2/DateRangePicker/styles';
import MonthAndYearOverlayPicker from '../MonthAndYearOverlayPicker';
import {
  DayText,
  DaysHeader,
  DayHeaderTitle,
  WeekContainer,
  NumberContainer,
  DotContainer,
} from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import { useAdminTranslation } from 'hooks/use-translation';

// tslint:disable-next-line: no-empty-interface
interface WeekPickerProps extends DateRangeProps {}

function WeekPicker({ isActive, onRangeChange, onTitleChange, setActive }: WeekPickerProps) {
  const { t } = useAdminTranslation();
  const [date, setDate] = useState<Moment>(moment());
  const [lookupDate, setLookupDate] = useState(moment());
  const [selectorTitle, setSelectorTitle] = useState('');
  const [isDatePickerActive, setDatePickerActive] = useState(false);

  useEffect(() => {
    if (!date) return;
    handleDateChange();
    handleTitleChange();
  }, [date]);

  useEffect(() => {
    if(!isActive) {
      setDatePickerActive(false);
    }
  }, [isActive]);

  const handleDateChange = () => {
    const startDate = moment(date).startOf('week');
    const endDate = moment(date).endOf('week');
    onRangeChange?.(startDate, endDate);
  };

  const handleTitleChange = () => {
    const month = date!.format('MMMM');
    const startDay = moment(date).startOf('week').format('Do');
    const endDay = moment(date).endOf('week').format('Do');
    const year = date!.format('YYYY');
    const newTitle = `${date ? `${month} ${startDay} - ${endDay}, ${year}` : ''}`;
    setSelectorTitle(newTitle);
    onTitleChange?.(newTitle);
  };

  const handleYearChange = (yearDate: Moment) => {
    setLookupDate(moment(lookupDate).set({ year: yearDate.year(), month: yearDate.month() }));
    setDatePickerActive(false);
  };

  const handleClickAddWeek = () => {
    const newDate = moment(lookupDate).add(7, 'day');
    setLookupDate(newDate);
    setDate(newDate);
  };

  const handleClickSubWeek = () => {
    const newDate = moment(lookupDate).subtract(7, 'day');
    setLookupDate(newDate);
    setDate(newDate);
  };

  const toggleDatePicker = () => {
    setDatePickerActive(!isDatePickerActive);
  };

  const calendar = useMemo(() => {
    const result: Array<Moment[]> = [];
    const startDay = lookupDate.clone().startOf('month').startOf('week');
    const endDay = lookupDate.clone().endOf('month').endOf('week');

    const currentDate = startDay.clone().subtract(1, 'day');

    while (currentDate.isBefore(endDay, 'day')) {
      result.push(
        Array(7).fill(null).map(() => {
          return currentDate.add(1, 'day').clone();
        }),
      );
    }
    return result;
  }, [lookupDate]);

  const calendarHeader = useMemo(() => {
    return Array(7).fill(null).map((_, index) => {
      return moment().set({ day: index }).format('ddd');
    });
  }, []);

  const renderCalendar = () => {
    return calendar.map((week: Moment[], index) => {
      const [firstDate] = week;
      const startOfWeek = firstDate.clone().startOf('week').set({ hour: 0, minute: 0, second: 0 });
      const endOfWeek = firstDate.clone().endOf('week').set({ hour: 23, minute: 59, second: 59 });
      const isSelected = date?.isBetween(startOfWeek, endOfWeek);

      const handleWeekClick = () => {
        const newDate = startOfWeek.clone().set({ hour: 1, minute: 0, second: 0 });
        setDate(newDate);
        setLookupDate(newDate);

        setTimeout(() => setActive?.(false), 200);
      };

      return (
        <WeekContainer key={index} onClick={handleWeekClick} isActive={isSelected}>
          <DotContainer>{!isSelected ? <Circle /> : <CheckIcon />}</DotContainer>
          <NumberContainer isActive={isSelected}>
            {week.map((day) => {
              const isDayInsideMonth = day.isBetween(
                lookupDate.clone().startOf('month'),
                lookupDate.clone().endOf('month'),
              );
              return (
                <DayText key={day.valueOf()} insideMonth={isDayInsideMonth} isActive={isSelected}>
                  {day.format('DD')}
                </DayText>
              );
            })}
          </NumberContainer>
        </WeekContainer>
      );
    });
  };

  return (
    <CalendarContainer isActive={isActive}>
      <CalendarHeader>
        <Title isSmall={false}>
          <TranslatedText stringKey="PICKER_SELECT_WEEK" />
        </Title>
        <CalendarYearInput
          onClick={toggleDatePicker}
          value={lookupDate?.format('MMM YYYY') || t('ADMIN_LABEL_SELECT_DATE')}
          isActive={isDatePickerActive}
        />
      </CalendarHeader>
      <SelectorContainer>
        <MonthAndYearOverlayPicker type={'Both'} isActive={isDatePickerActive} onDateChange={handleYearChange} />
        <SelectorTitle>{selectorTitle}</SelectorTitle>
        <PreviousButton onClick={handleClickSubWeek} />
        <NextButton onClick={handleClickAddWeek} />
      </SelectorContainer>
      <DaysHeader>
        {calendarHeader.map((day) => {
          return <DayHeaderTitle key={day}>{day}</DayHeaderTitle>;
        })}
      </DaysHeader>
      {renderCalendar()}
      <Tip>{t('ADMIN_WEEK_PICKER_TIP')}</Tip>
    </CalendarContainer>
  );
}

export default WeekPicker;
